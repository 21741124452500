<template>
  <v-card class="overflow-hidden mt-5">
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon
        @click="$emit('toggleShow', 'contact')"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Nouveau contact
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiCardAccountPhoneOutline }}
      </v-icon>
    </v-card-title>
    <v-divider>
    </v-divider>
    <v-expand-transition>
      <div v-show="show">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-card-text>
            <v-row align="center">
              <v-col cols="6">
                <v-text-field
                  v-if="!editContacter"
                  v-model="newContact.contacter"
                  :rules="[v => !!v || 'Le contacteur est requis']"
                  readonly
                  :append-icon="icons.mdiPencil"
                  @click:append="editContacter = !editContacter"
                ></v-text-field>
                <v-select
                  v-else
                  v-model="newContact.contacter"
                  :items="$store.state.userlist"
                  item-text="displayName"
                  item-value="displayName"
                  label="Contacté par"
                  outline
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="newContact.contactedby"
                  :items="contactWays"
                  :rules="[v => !!v || 'Le moyen de contact est requis']"
                  item-text="name"
                  item-value="value"
                  label="via"
                  outline
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <StudentSingleTexteTypeSelectVue
              @updateNote="updateNote"
            ></StudentSingleTexteTypeSelectVue>
          </v-card-text>
          <v-card-text>
            <v-textarea
              v-model="newContact.note"
              outlined
              name="input-7-4"
              label="Note"
            ></v-textarea>
          </v-card-text>
        </v-form>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="newContact.contactResult"
                :items="statusExpandArr"
                :rules="[v => !!v || 'Le moyen de contact est requis']"
                item-text="text"
                item-value="value"
                label="Résultat"
                outline
              ></v-select>
            </v-col>
            <v-col
              md="6"
              sm="12"
              class="text-center"
            >
              <DateTimePickerVue
                color="info"
                button-text="Modifier date"
                @newDate="updateContactDate"
              ></DateTimePickerVue>
            </v-col>
            <v-col
              cols="12"
              class="text-center"
            >
              <v-btn
                :disabled="disableBtn"
                color="primary"
                class="me-3 mt-4"
                @click.prevent="updateContacts"
              >
                Ok
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import {
  mdiPencil,
  mdiCardAccountPhoneOutline,
  mdiChevronUp,
  mdiChevronDown,
} from '@mdi/js'

import DateTimePickerVue from '../DateTimePicker.vue'
import StudentSingleTexteTypeSelectVue from './StudentSingleNewContactCardTexteTypeSelect.vue'

export default {
  components: {
    DateTimePickerVue,
    StudentSingleTexteTypeSelectVue,
  },
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
    student: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      valid: true,
      disableBtn: false,
      newContact: {
        contacter: this.$store.state.user.displayName,
        contactedby: '',
        note: '',
        contactDate: new Date(),
        contactResult: 'suivi',
      },
      editContacter: false,
      statusExpandArr: [
        {
          value: 'enFormation',
          color: 'primary',
          text: 'Dossier en cours',
        },
        {
          value: 'suivi',
          color: 'primary',
          text: 'Suivi de formation',
        },
        {
          value: 'debutForm',
          color: 'primary',
          text: 'Début de Formation',
        },
        {
          value: 'finForm',
          color: 'primary',
          text: 'Fin de Formation',
        },
        {
          value: 'rappel',
          color: 'primary',
          text: 'A rappeler',
        },
        {
          value: 'dead',
          color: 'error',
          text: 'Ne plus contacter',
        },
        {
          value: 'nrp',
          color: 'warning',
          text: 'Ne répond pas',
        },
        {
          value: 'autre',
          color: 'secondary',
          text: 'Autre',
        },
      ],
      contactWays: [
        {
          value: 'phone',
          name: 'Téléphone',
        },
        {
          value: 'mail',
          name: 'E-mail',
        },
        {
          value: 'text',
          name: 'Texto',
        },
        {
          value: 'visio',
          name: 'Visio',
        },
        {
          value: 'other',
          name: 'Autre',
        },
      ],
      icons: {
        mdiPencil,
        mdiCardAccountPhoneOutline,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
  methods: {
    updateNote(payload) {
      this.newContact.note = payload
    },
    updateContacts() {
      if (this.$refs.form.validate()) {
        this.disableBtn = true
        const contactsArr = [...this.contacts]
        contactsArr.push(this.newContact)
        this.$http.put(`${process.env.VUE_APP_API_URL}/students/${this.student.id}`, { contacts: contactsArr })
          .then(response => {
            this.$emit('newContact', { contacts: response.data.contacts })
            this.$store.commit('snackMe', {
              color: 'success',
              text: 'Point de contact mis à jour',
              value: true,
            })
            this.newContact = {
              contacter: this.$store.state.user.displayName,
              contactedby: this.newContact.contactedby,
              note: '',
              contactDate: new Date(),
              contactResult: 'suivi',
            }
          })
          .catch(error => {
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'Erreur lors de la mise à jour du point de contact',
              value: true,
            })
            console.error(error)
          })
          .finally(this.disableBtn = false)
      } else {
        this.$refs.form.validate()
      }
    },
    updateContactDate(e) {
      this.newContact.contactDate = e
    },
  },
}
</script>

<style>

</style>
