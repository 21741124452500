<template>
  <v-select
    v-if="!loading"
    :items="textes"
    item-text="title"
    item-value="content"
    label="Textes types"
    outline
    :clearable="true"
    @change="updateNote"
  ></v-select>
</template>

<script>
export default {
  data() {
    return {
      textes: [],
      loading: true,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/textes?categorie=contact`).then(
        responseTexts => {
          this.textes = responseTexts.data
          this.loading = false
        },
        error => {
          console.log(error)
        },
      )
    },
    updateNote(e) {
      this.$emit('updateNote', e)
    },
  },
}
</script>

<style>

</style>
