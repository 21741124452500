<template>
  <v-card class="overflow-hidden">
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon
        @click="$emit('toggleShow', 'rappel')"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Rappel
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiPhoneOutline }}
      </v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <v-expand-transition>
      <div v-show="show">
        <v-card-text
          v-if="!student.rappeled"
          class="text-center"
        >
          <p>Stagiaire à rappeler le : <b>{{ new Date(student.rappel) | date('dd MMMM yyyy à HH:mm') }}</b></p>
        </v-card-text>
        <v-card-text v-else>
          <p>Pas de rappel prévu</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text
          class="text-center"
        >
          <h3 class="mb-3">
            Nouveau Rappel
          </h3>
          <v-row>
            <v-col
              md="6"
              sm="12"
              class="mt-3"
            >
              <DateTimePickerVue
                :with-tasks="true"
                :no-time="notime"
                color="primary"
                :button-text="notime? 'Date' : 'Date et heure'"
                @newDate="changeDateRappel"
              ></DateTimePickerVue>
              <v-checkbox
                v-model="notime"
                label="Pas d'heure spécifique"
              ></v-checkbox>
            </v-col>
            <v-col
              md="6"
              sm="12"
            >
              <v-select
                ref="taskUserSelector"
                v-model="newTask.users_permissions_user"
                :items="$store.state.userlist"
                item-text="displayName"
                item-value="id"
                label="Attribué à"
                outline
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-textarea
            v-model="newTask.note"
            outlined
            name="input-7-4"
            label="Note"
          ></v-textarea>
        </v-card-text>
        <v-card-text class="d-flex justify-space-between">
          <v-select
            v-model="newTask.type"
            :items="taskTypes"
            item-text="niceName"
            item-value="name"
            label="A faire : "
            outline
            class="mr-5"
          ></v-select>

          <v-btn
            :disabled="disableBtn"
            color="primary"
            class="me-3 mt-4"
            @click.prevent="createNewTask"
          >
            Ok
          </v-btn>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mdiPhoneOutline, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import DateTimePickerVue from '../DateTimePicker.vue'

export default {
  components: {
    DateTimePickerVue,
  },
  props: {
    student: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      disableBtn: false,
      notime: false,
      newTask: {
        users_permissions_user: null,
        note: '',
        date: new Date(),
        student: 0,
        status: 'todo',
        type: 'rappel',
      },
      newRappel: new Date(),

      taskTypes: [
        {
          name: 'rappel',
          niceName: 'Rappel',
        },
        {
          name: 'suivi',
          niceName: 'Suivi',
        },
        {
          name: 'entree',
          niceName: 'Entrée en formation',
        },
        {
          name: 'sortie',
          niceName: 'Sortie de formation',
        },
      ],
      icons: {
        mdiPhoneOutline,
        mdiChevronUp,
        mdiChevronDown,
      },

    }
  },
  mounted() {
    this.$refs.taskUserSelector.selectedItems = [this.$store.state.user]
  },
  methods: {
    changeDateRappel(e) {
      this.newRappel = e
      this.newTask.date = e
      this.$http.put(`${process.env.VUE_APP_API_URL}/students/${this.student.id}`, { rappel: this.newRappel, rappeled: false })
        .then(res => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Date de rappel selectionnée',
            value: true,
          })
          this.$emit('updateStudent', res.data)
        })
        .catch(error => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la modification du rappel',
            value: true,
          })
          console.error(error)
        })
    },
    createNewTask() {
      this.disableBtn = true
      this.newTask.student = this.student.id
      this.$http.post(`${process.env.VUE_APP_API_URL}/tasks/`, this.newTask)
        .then(() => {
          this.$emit('studentUpdate', { rappeled: false, rappel: this.newRappel })
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Nouveau rappel créé',
            value: true,
          })
          this.newTask.note = ''
          this.$emit('studentUpdate', {
            rappeled: false,
            rappel: this.newTask.date,
          })
        })
        .catch(error => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la création du rappel',
            value: true,
          })
          console.log(error)
        })
        .finally(this.disableBtn = false)
    },
  },
}
</script>

<style>

</style>
