<template>
  <v-card class="overflow-hidden">
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon
        @click="$emit('toggleShow', 'files')"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Documents
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiFileCabinet }}
      </v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <v-expand-transition>
      <div v-show="show">
        <v-row
          class="ma-4 h-full"
          justify="center"
        >
          <v-col
            v-for="(file, index) in fichiers"
            :key="index"
            cols="6"
            md="6"
            class="text-center"
          >
            <v-icon>
              {{ file.ext == '.pdf' ? icons.mdiFilePdfBox : icons.mdiFileOutline }}
            </v-icon>
            {{ file.name }}
            <v-col
              cols="12"
              class="text-center"
            >
              <v-btn
                color="success"
                outlined
                class="mt-4 me-3"
                @click.prevent="showFile(file)"
              >
                Voir
              </v-btn>
              <v-btn
                color="primary"
                class="me-3 mt-4"
                :href="linkToFile(file.url)"
                target="_blank"
              >
                <v-icon>
                  {{ icons.mdiDownload }}
                </v-icon>
                Télécharger
              </v-btn>
              <v-btn
                color="danger"
                outlined
                class="mt-4"
                @click.prevent="removeMedia(file.id)"
              >
                Supprimer
              </v-btn>
            </v-col>
          </v-col>
          <v-col
            cols="12"
          >
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row
          class="ma-5 h-full"
        >
          <v-col
            cols="12"
          >
            <v-file-input
              v-model="newFiles"
              chips
              multiple
              label="Ajouter des fichiers"
            ></v-file-input>
          </v-col>
          <v-col
            cols="12"
            class="text-center"
          >
            <v-btn
              color="primary"
              :disabled="newFiles.length <= 0"
              class="me-3 mt-4"
              @click.prevent="uploadFiles"
            >
              Ok
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>

import {
  mdiFilePdfBox, mdiFileOutline, mdiDownload, mdiFileCabinet, mdiChevronUp, mdiChevronDown,
} from '@mdi/js'

export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    fichiers: {
      type: Array,
      default: () => [],
    },
    studentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      newFiles: [],
      icons: {
        mdiFilePdfBox,
        mdiFileOutline,
        mdiDownload,
        mdiFileCabinet,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
  methods: {
    showFile(a) {
      window.open(`${process.env.VUE_APP_API_URL + a.url}`)
    },
    linkToFile(a) {
      return `${process.env.VUE_APP_API_URL + a}`
    },
    uploadFiles() {
      const formData = new FormData()
      let i = 0
      this.newFiles.forEach(file => {
        formData.append('files', file, file.name)
        i += 1
      })
      this.$http.post(`${process.env.VUE_APP_API_URL}/upload`, formData)
        .then(response => {
          const newFichiersArray = this.fichiers
          response.data.forEach(fichier => {
            newFichiersArray.push(fichier.id)
          })
          this.$http.put(`${process.env.VUE_APP_API_URL}/students/${this.studentId}`, { fichier: newFichiersArray })
            .then(responseStudent => {
              this.$emit('studentUpdate', { fichier: responseStudent.data.fichier })
              this.$store.commit('snackMe', {
                color: 'success',
                text: `${i} Fichier(s) ajouté(s)`,
                value: true,
              })
              this.newFiles = []
            })
            .catch(error => {
              this.$store.commit('snackMe', {
                color: 'error',
                text: 'Erreur lors de l\'ajout des fichiers',
                value: true,
              })
              console.log(error)
            })
        })
        .catch(error => console.error(error))
    },
    removeMedia(id) {
      const fileDeleted = this.fichiers.filter(obj => obj.id !== id)

      this.$http.put(`${process.env.VUE_APP_API_URL}/students/${this.studentId}`, { fichier: fileDeleted })
        .then(response => {
          this.$emit('studentUpdate', { fichier: response.data.fichier })

          this.$http.delete(`${process.env.VUE_APP_API_URL}/upload/files/${id}`)
            .then(() => {
              this.$store.commit('snackMe', {
                color: 'success',
                text: 'Fichier supprimé',
                value: true,
              })
            })
            .catch(err => {
              console.log(err)
              this.$store.commit('snackMe', {
                color: 'error',
                text: 'Erreur lors de la suppression du fichier',
                value: true,
              })
            })
        })
        .catch(error => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la suppression du fichier',
            value: true,
          })
          console.log(error)
        })
    },
  },

}
</script>

<style>

</style>
