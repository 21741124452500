<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="700"
      persistent
      @input="$emit('input', $event)"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Document de suivi
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="generateDocsuivi"
          >
            Générer le document
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="$emit('closeModal')"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: {
    student: {
      type: Object,
      default: () => { },
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    generateDocsuivi() {
      // POST TO PDF GENERATOR
      this.student.dateFormation = {
        debutFormation: this.$date(new Date(this.student.debutFormation), 'dd/MM/yyyy'),
        finFormation: this.$date(new Date(this.student.finFormation), 'dd/MM/yyyy'),
      }
      this.student.visioConf = {
        visio: this.student.visio ? 'Oui' : 'Non',
        duree: this.student.dureeVisio ? this.student.dureeVisio : 1,
      }
      const contactSorted = this.student.contacts.sort((a, b) => new Date(a.contactDate) - new Date(b.contactDate))

      this.student.contactsNice = []

      contactSorted.forEach(contact => {
        this.student.contactsNice.push({
          contactDate: this.$date(new Date(contact.contactDate), 'dd/MM/yyyy'),
          contactResult: this.niceContact(contact.contactResult),
          contactedby: this.niceContact(contact.contactedby),
          contacter: contact.contacter,
          note: contact.note,
        })
      })
      this.$http.post(`${process.env.VUE_APP_API_URL}/students/pdf/docsuivi/${this.student.id}`, this.student)
        .then(pdf => {
          this.$http.post(`${process.env.VUE_APP_API_URL}/docsuivis`, {
            student: this.student.id, pdf: pdf.data[0].id, createdBy: this.$store.state.user.id,
          })
            .then(res => {
              this.$emit('docSuiviGenerated', res.data)
              this.$store.commit('snackMe', {
                color: 'success',
                text: 'Document de suivi générée avec succès',
                value: true,
              })
            })
            .catch(err => console.log(err))
        })
        .catch(error => console.error(error))
    },
    niceContact(a) {
      switch (a) {
        case 'enFormation':
        case 'suivi':
          return 'Suivi'
        case 'phone':
          return 'Téléphone'
        case 'mail':
          return 'E-mail'
        default:
          return 'Non trouvé'
      }
    },
  },
}
</script>

<style>

</style>
