<template>
  <v-card
    class="overflow-hidden"
    justify="start"
  >
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon
        @click="$emit('toggleShow', 'history')"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Historique
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiHistory }}
      </v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <v-expand-transition>
      <div v-show="show">
        <v-timeline>
          <v-timeline-item
            v-for="(interaction, index) in interactions"
            :key="index"
            left
            :icon="getIcon(interaction.contactedby)"
          >
            <span
              slot="opposite"
              style="max-width: 200px;"
            >
              <span class=""> {{ new Date(interaction.contactDate) | date('dd MMMM yyyy') }} <br>
                Contacté par <b>{{ interaction.contacter }}</b>
              </span>
              <span
                class="text-center"
                justify-center
              >
                via
                <v-icon
                  size="1.5rem"
                  color="primary"
                  class="me-2"
                >
                  {{ getIcon(interaction.contactedby) }}
                </v-icon>
              </span>
            </span>

            <v-card
              elevation="7"
              :color="statusColor[interaction.contactResult].color"
              class="mb-2 mx-5"
            >
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 text-black">
                    {{ statusColor[interaction.contactResult].text }}
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-card-subtitle class="pb-0">
                Commentaire :
              </v-card-subtitle>
              <v-card-text
                class="text mb-2 word-wrap text-black"
              >
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p v-html="replacelineBreak(interaction.note)"></p>
              </v-card-text>
              <template v-if="interaction.contacter !== 'CRM'">
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    outlined
                    rounded
                    class="ma-2"
                    color="error"
                    @click="deleteContact(interaction._id)"
                  >
                    <v-icon
                      color="red"
                    >
                      {{ icons.mdiTrashCanOutline }}
                    </v-icon>
                    Supprimer
                  </v-btn>
                </v-card-actions>
              </template>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import {
  mdiPhoneOutline,
  mdiEmailOutline,
  mdiMessageReplyTextOutline,
  mdiCertificateOutline,
  mdiTrashCanOutline,
  mdiHistory,
  mdiChevronUp,
  mdiChevronDown,
  mdiVideoOutline,
} from '@mdi/js'

export default {
  props: {
    show: {
      type: Boolean,
      deault: true,
    },
    contact: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      statusColor: {
        enFormation:
      {
        color: '#8BC34A',
        text: 'Suivi de formation',
      },
        rappel:
      {
        color: '#8BC34A',
        text: 'Suivi de formation',
      },
        suivi:
      {
        color: '#8BC34A',
        text: 'Suivi de formation',
      },
        debutForm:
      {
        color: '#81C784',
        text: 'Début de Formation',
      },
        finForm:
      {
        color: '#FFECB3',
        text: 'Fin de Formation',
      },
        sortieFormation:
      {
        color: '#FFECB3',
        text: 'Sortie de formation',
      },
        dead:
      {
        color: '#EF5350',
        text: 'Caca',
      },
        nrp:
      {
        color: 'warning',
        text: 'Ne répond pas',
      },
        inscritCertif:
      {
        color: '#009688',
        text: 'Inscrit à la certification',
      },
        autre:
      {
        color: 'secondary',
        text: 'Autre',
      },
      },
      contactWays: [
        {
          value: 'phone',
          name: 'Téléphone',
          icon: 'mdiPhoneOutline',
        },
        {
          value: 'mail',
          name: 'E-mail',
          icon: 'mdiEmailOutline',
        },
        {
          value: 'text',
          name: 'Texto',
          icon: 'mdiMessageReplyTextOutline',
        },
        {
          value: 'visio',
          name: 'Visio',
          icon: 'mdiVideoOutline',
        },
        {
          value: 'formation',
          name: 'Formation',
          icon: 'mdiCertificateOutline',
        },
        {
          value: 'other',
          name: 'Autre',
          icon: 'mdiAlertCircleOutline',
        },
      ],
      icons: {
        mdiEmailOutline,
        mdiMessageReplyTextOutline,
        mdiCertificateOutline,
        mdiPhoneOutline,
        mdiTrashCanOutline,
        mdiHistory,
        mdiChevronUp,
        mdiChevronDown,
        mdiVideoOutline,
      },
    }
  },
  methods: {
    replacelineBreak(content) {
      if (content) {
        return content.replace(/(?:\r\n|\r|\n)/g, '<br />')
      }

      return 'Pas de contenu'
    },
    getIcon(a) {
      const iconToGet = this.contactWays.find(x => x.value === a).icon

      return this.icons[iconToGet]
    },
    getName(a) {
      return this.contactWays.find(x => x.value === a).name
    },
    deleteContact(id) {
      const contactDeleted = this.studentContacts.filter(obj => obj.id !== id)
      this.$http.put(`${process.env.VUE_APP_API_URL}/students/${this.studentId}`, { contacts: contactDeleted })
        .then(response => {
          this.$emit('studentUpdate', { contacts: response.data.contacts })
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Point de contact supprimé avec succès',
            value: true,
          })
        })
        .catch(error => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la mise à jour des points de contacts',
            value: true,
          })
          console.log(error)
        })
    },
  },

}
</script>

<style>
  .text-black {
    color: #000 !important
  }
</style>
