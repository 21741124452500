<template>
  <div>
    <v-row
      class="ma-0 h-full"
    >
      <!-- Carte Info Persos -->
      <v-col
        md="6"
        sm="12"
      >
        <div v-if="loading">
          <v-skeleton-loader
            type="article,list-item-three-line, list-item-three-line,list-item-three-line, actions"
            class="mb-4"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="article,list-item-three-line, list-item-three-line,list-item-two-line"
            class="mb-4"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="table"
          ></v-skeleton-loader>
        </div>
        <template v-else>
          <ContactSinglePersonnalInfosCardVue
            :contact="contact"
            :show="cardPreference.info"
            @toggleShow="toggleShow"
          ></ContactSinglePersonnalInfosCardVue>

          <!-- Carte Formation -->
          <ContactSingleCourseCardVue
            :show="cardPreference.course"
            :contact="contact"
            @toggleShow="toggleShow"
            @contactUpdate="updateContact"
          ></ContactSingleCourseCardVue>
          <!-- Fin Carte Formations -->
          <!-- DOCUMENTS -->
          <StudentSingleDocumentsCardVue
            :show="cardPreference.documents"
            :contact="contact"
            @toggleShow="toggleShow"
          ></StudentSingleDocumentsCardVue>
        <!-- FIN DOCUMENTS-->
        </template>
      </v-col>

      <!-- Colonne de droite-->
      <v-col
        md="6"
        sm="12"
      >
        <div v-if="loading">
          <v-skeleton-loader
            type="article, list-item-three-line, list-item-three-line, actions"
            class="mb-4"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="article, actions"
            class="mb-4"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="article, actions"
            class="mb-4"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="article, actions"
            class="mb-4"
          ></v-skeleton-loader>
        </div>
        <template v-else>
          <!-- MEETINGS CARD -->
          <StudentSingleMeetingsCardVue
            :show="cardPreference.visio"
            :contact="contact"
            :meetings="meetings"
            @meetingAdded="meetingAdded"
            @toggleShow="toggleShow"
            @meetingUpdated="updateMeeting"
          ></StudentSingleMeetingsCardVue>
          <!-- END MEETING CARD -->

          <!-- CARTE RAPPEL -->
          <StudentSingleRappelCardVue
            :show="cardPreference.rappel"
            :contact="contact"
            @updateStudent="studentUpdate"
            @toggleShow="toggleShow"
          ></StudentSingleRappelCardVue>
          <!-- FIN CARTE RAPPEL -->
          <!-- NOUVEAU CONTACT-->
          <StudentSingleNewContactCardVue
            :show="cardPreference.contact"
            :contact="contact"
            :contacts="contact.contacts"
            @toggleShow="toggleShow"
            @newContact="newContact"
          ></StudentSingleNewContactCardVue>
        <!-- FIN NOUVEAU CONTACT-->
        </template>
      </v-col>
    </v-row>
    <v-row
      class="ma-0 h-full"
    >
      <!-- NOUVELLE CARTE CONTACTS -->
      <v-col
        cols="12"
      >
        <v-skeleton-loader
          v-if="loading"
          type="article, actions"
        ></v-skeleton-loader>
        <ContactSingleContactsHistoryCardVue
          v-else
          :show="cardPreference.history"
          :contact="contact"
          @studentUpdate="studentUpdate"
          @toggleShow="toggleShow"
        ></ContactSingleContactsHistoryCardVue>
      </v-col>
      <!-- CARTE FIN CONTACTS -->
    </v-row>
    <v-row
      class="ma-0 h-full"
    >
      <v-col>
        <v-skeleton-loader
          v-if="loading"
          type="article, actions"
        ></v-skeleton-loader>
        <!-- CARTE FICHIERS -->
        <StudentSingleFichiersCardVue
          v-else
          :show="cardPreference.files"
          :fichiers="contact.fichier"
          :contact-id="contact._id"
          @studentUpdate="studentUpdate"
          @toggleShow="toggleShow"
        ></StudentSingleFichiersCardVue>
      <!-- FIN CARTE FICHIERS -->
      </v-col>
    </v-row>
  </div>
</template>

<script>

import ContactSinglePersonnalInfosCardVue from '@/components/StudentSingle/ContactSinglePersonnalInfosCard.vue'
import ContactSingleCourseCardVue from '@/components/StudentSingle/ContactSingleCourseCard.vue'
import ContactSingleContactsHistoryCardVue from '@/components/StudentSingle/ContactSingleContactsHistoryCard.vue'
import StudentSingleDocumentsCardVue from '@/components/StudentSingle/StudentSingleDocumentsCard.vue'
import StudentSingleRappelCardVue from '@/components/StudentSingle/StudentSingleRappelCard.vue'
import StudentSingleNewContactCardVue from '@/components/StudentSingle/StudentSingleNewContactCard.vue'
import StudentSingleFichiersCardVue from '@/components/StudentSingle/StudentSingleFichiersCard.vue'
import StudentSingleMeetingsCardVue from '@/components/StudentSingle/StudentSingleMeetingsCard.vue'

export default {

  components: {
    ContactSinglePersonnalInfosCardVue,
    ContactSingleCourseCardVue,
    ContactSingleContactsHistoryCardVue,
    StudentSingleDocumentsCardVue,
    StudentSingleRappelCardVue,
    StudentSingleNewContactCardVue,
    StudentSingleFichiersCardVue,
    StudentSingleMeetingsCardVue,
  },
  props: {

  },
  data() {
    return {
      loading: true,
      contact: {},
      meetings: [],
      cardPreference: this.$store.state.user.preferences,
      studentStatus: {
        enFormation: {
          color: 'primary',
          text: 'En formation',
        },
        avantFormation: {
          color: 'success',
          text: 'Pas encore entré en formation',
        },
        sortieFormation: {
          color: 'warning',
          text: 'Sorti de formation',
        },
        autre: {
          color: 'info',
          text: 'Autre',
        },
      },

      statusExpand: {
        rappel:
      {
        color: 'primary',
        text: 'A rappeler',
      },
        suivi:
      {
        color: 'info',
        text: 'Suivi de dossier',
      },
        enFormation:
      {
        color: 'primary',
        text: 'Dossier en cours',
      },
        debutForm:
      {
        color: 'primary',
        text: 'Début de Formation',
      },
        finForm:
      {
        color: 'primary',
        text: 'Fin de Formation',
      },
        dead:
      {
        color: 'error',
        text: 'Caca',
      },
        nrp:
      {
        color: 'warning',
        text: 'Ne répond pas',
      },
        autre:
      {
        color: 'secondary',
        text: 'Autre',
      },
      },
    }
  },
  computed: {
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData',

  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      Promise.all([this.fetchContact()])
        .then(values => {
          [this.contact] = values
        })
        .catch(error => console.log(error))
        .finally(() => {
          if (this.contact) {
            this.loading = false
          }
        })
    },
    async fetchContact() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/contact/find-one/${this.$route.params.id}?with=["meetings","courses.course","courses.tutor","courses.course.certification"]`)
      console.log(res.data)

      return res.data
    },

    studentUpdate(e) {
      Object.keys(e).forEach(key => {
        this.contact[key] = e[key]
      })
    },
    newContact(payload) {
      this.contact.contacts = payload.contacts
    },
    updateStatus(e) {
      this.$http.put(`${process.env.VUE_APP_API_URL}/students/${this.contact.id}`, { status: e })
        .then(response => {
          this.contact.status = response.data.status
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Status changé avec succès.',
            value: true,
          })
        })
        .catch(error => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la mise à jour du status',
            value: true,
          })
          console.log(error)
        })
    },
    updateContact(payload) {
      Object.keys(payload).forEach(element => {
        this.contact[element] = payload[element]
      })
    },
    getIcon(a) {
      const iconToGet = this.contactWays.find(x => x.value === a).icon

      return this.icons[iconToGet]
    },
    meetingAdded(payload) {
      this.meetings.push(payload)
    },
    toggleShow(payload) {
      this.cardPreference[payload] = !this.cardPreference[payload]
      this.$nextTick(() => {
        this.$store.dispatch('updateUserPreferences', { target: payload, value: !this.cardPreference[payload] })
      })
    },
    updateMeeting(payload) {
      const meetings = [...this.meetings]
      const foundIndex = this.meetings.findIndex(x => x.id === payload.id)

      meetings[foundIndex] = payload
      this.meetings = meetings
    },
  },
}
</script>
