import { render, staticRenderFns } from "./StudentSingleRappelCard.vue?vue&type=template&id=f32f29fe&"
import script from "./StudentSingleRappelCard.vue?vue&type=script&lang=js&"
export * from "./StudentSingleRappelCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports