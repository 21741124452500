<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="700"
      persistent
      @input="$emit('input', $event)"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Informations de la facture
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="justify-center">
            <v-col>
              <v-select
                v-model="invoiceData.number"
                outlined
                :items="invoices"
                label="Numéro de facture"
                :item-text="item=> `${item.number} - ${item.student.nom} ${item.student.prenom}`"
                item-value="number"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="student.nom"
                label="Nom"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="student.prenom"
                label="Prénom"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="student.price"
                label="Prix (€)"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="student.formation.nom"
                label="Formation"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="invoiceData.duree"
                label="Durée (h)"
                dense
                :append-icon="icons.mdiPencil"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="invoiceData.dateFacturation"
                label="Date de facture"
                readonly
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="generateInvoice"
          >
            Générer la facture
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="$emit('closeModal')"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiPencil } from '@mdi/js'

export default {

  props: {
    student: {
      type: Object,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      invoices: [],
      invoiceData: {
        dateFacturation: this.$date(new Date(), 'dd/MM/yyyy'),
        duree: 0,
        number: 0,
      },
      icons: {
        mdiPencil,
      },
    }
  },
  computed: {
    echeance() {
      return this.$date(new Date(new Date().setDate(new Date().getDate() + 30)), 'dd/MM/yyyy')
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.invoiceData.duree = this.student.formation.duree
        this.$http.get(`${process.env.VUE_APP_API_URL}/factures?student.id=${this.student.id}`)
          .then(res => {
            if (res.data.length > 0) {
              this.invoices = res.data
              this.invoiceData.number = res.data[0].number
            } else {
              this.$http.get(`${process.env.VUE_APP_API_URL}/factures?_sort=number`)
                .then(invoices => {
                  this.invoices = invoices.data

                  let nb = 0
                  this.invoices.forEach(invoice => {
                    if (nb < invoice.number) {
                      nb = invoice.number
                    }
                  })
                  nb += 1
                  this.invoiceData.number = nb
                  this.invoices.push({
                    number: nb,
                    student: {
                      nom: 'Nouvelle facture',
                      prenom: '',
                    },
                  })
                  this.invoices = this.invoices.filter(x => x.student !== null)
                })
                .catch(err => console.log(err))
            }
          })
          .catch(err => console.log(err))
      }
    },
  },
  methods: {
    invoiceInfo(a) {
      return a
    },
    generateInvoice() {
      // FORMAT DATA FOR PDF
      this.invoiceData.echeance = this.echeance
      this.invoiceData.student = this.student
      this.invoiceData.debutFormation = this.$date(new Date(this.student.debutFormation), 'dd/MM/yyyy')
      this.invoiceData.finFormation = this.$date(new Date(this.student.finFormation), 'dd/MM/yyyy')

      // POST TO PDF GENERATOR
      this.$http.post(`${process.env.VUE_APP_API_URL}/students/pdf/invoice/${this.student.id}`, this.invoiceData)
        .then(pdf => {
          // LINK TO INVOICE COLLECTION
          this.$http.post(`${process.env.VUE_APP_API_URL}/factures`, {
            number: this.invoiceData.number, student: this.student.id, pdf: pdf.data[0].id, createdBy: this.$store.state.user.id,
          })
            .then(res => {
              this.$emit('invoiceGenerated', res.data)
              this.$store.commit('snackMe', {
                color: 'success',
                text: 'Facture générée avec succès',
                value: true,
              })
            })
            .catch(err => { console.log(err) })
        })
        .catch(error => {
          if (error.response.status === 400) {
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'Erreur sur le numéro de facture, rechargez la page.',
              value: true,
            })
          }
          console.error(error.response)
        })
    },
  },
}
</script>

<style>

</style>
