<template>
  <v-card
    class="overflow-hidden my-5"
  >
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon
        @click="$emit('toggleShow', 'documents')"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Documents à génerer
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiFile }}
      </v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <v-expand-transition>
      <div v-show="show">
        <v-list two-line>
          <template v-for="(item, index) in items">
            <v-list-item :key="item.title">
              <template>
                <v-list-item-icon>
                  <v-icon
                    v-if="item.generated"
                    disabled
                  >
                    {{ icons.mdiCheckboxMarkedOutline }}
                  </v-icon>
                  <v-icon
                    v-else
                    disabled
                  >
                    {{ icons.mdiCheckboxBlankOutline }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>

                  <v-list-item-subtitle
                    class="text--primary"
                    v-text="item.headline"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="mr-4"
                        color="primary"
                        :disabled="item.title === 'Formulaire de satisfaction' && (!student.review)"
                        v-on="on"
                        @click="solveFile(item)"
                      >
                        {{ icons.mdiRefresh }}
                      </v-icon>
                    </template>
                    <span>Générer</span>
                  </v-tooltip>
                </v-list-item-action>

                <v-list-item-action>
                  <a
                    v-if="item.generated"
                    @click="linkToFile(item)"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :disabled="!item.generated"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ icons.mdiDownload }}
                        </v-icon>
                      </template>
                      <span>Télécharger</span>
                    </v-tooltip>
                  </a>
                  <div v-else>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :disabled="!item.generated"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ icons.mdiDownload }}
                        </v-icon>
                      </template>
                      <span>Télécharger</span>
                    </v-tooltip>
                  </div>
                </v-list-item-action>
                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        color="primary"
                        :disabled="!item.generated"
                        v-on="on"
                        @click="showFile(item)"
                      >
                        {{ icons.mdiEyeOutline }}
                      </v-icon>
                    </template>
                    <span>Visionner</span>
                  </v-tooltip>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider
              v-if="index < items.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list>
        <StudentInvoiceDialogVue
          :student="student"
          :dialog="factureDialog"
          @closeModal="factureDialog = false"
          @invoiceGenerated="updateInvoice"
        ></StudentInvoiceDialogVue>
        <StudentConventionDialogVue
          :student="student"
          :dialog="conventionDialog"
          @closeModal="conventionDialog = false"
          @conventionGenerated="updateConvention"
        ></StudentConventionDialogVue>
        <StudentDocsuiviDialogVue
          :student="student"
          :dialog="docSuiviDialog"
          @closeModal="docSuiviDialog = false"
          @docSuiviGenerated="updateDocsuivi"
        ></StudentDocsuiviDialogVue>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import {
  mdiEyeOutline,
  mdiDownload,
  mdiRefresh,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
  mdiFile,
  mdiChevronUp,
  mdiChevronDown,
} from '@mdi/js'
import StudentInvoiceDialogVue from '../StudentInvoiceDialog.vue'
import StudentConventionDialogVue from '../StudentConventionDialog.vue'
import StudentDocsuiviDialogVue from '../StudentDocsuiviDialog.vue'

export default {
  components: {
    StudentInvoiceDialogVue,
    StudentConventionDialogVue,
    StudentDocsuiviDialogVue,
  },

  props: {
    student: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      factureDialog: false,
      conventionDialog: false,
      docSuiviDialog: false,
      icons: {
        mdiEyeOutline,
        mdiRefresh,
        mdiDownload,
        mdiCheckboxBlankOutline,
        mdiCheckboxMarkedOutline,
        mdiFile,
        mdiChevronUp,
        mdiChevronDown,
      },
      selected: [],
      facture: {
        title: 'Facture',
        headline: 'Pas encore créé',
        generated: false,
        url: '',
      },
      creipef:
         {
           title: 'Compte Rendu d\'Entretient',
           headline: 'Pas encore créé',
           generated: false,
           url: '',
         },
      convention:
         {
           title: 'Convention',
           headline: 'Pas encore créé',
           generated: false,
           url: '',
         },
      satisfaction:
         {
           title: 'Formulaire de satisfaction',
           headline: 'Pas encore créé',
           generated: false,
           url: '',
         },
      docsuivi:
         {
           title: 'Document de suivi',
           headline: 'Pas encore créé',
           generated: false,
           url: '',
         },
    }
  },
  computed: {
    items() {
      const infos = []
      infos.push(this.facture, this.creipef, this.convention, this.docsuivi, this.satisfaction)

      return infos
    },
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData',

  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/factures?student.id=${this.$route.params.id}`)
        .then(res => {
          if (res.data.length > 0) {
            const newFac = res.data[0]
            this.updateInvoice(newFac)
          }
        })
        .catch(err => console.log(err))
      this.$http.get(`${process.env.VUE_APP_API_URL}/creipefs?student.id=${this.$route.params.id}`)
        .then(res => {
          if (res.data.length > 0) {
            const newCreip = res.data[0]
            this.updateCreipef(newCreip)
          }
        })
        .catch(err => console.log(err))
      this.$http.get(`${process.env.VUE_APP_API_URL}/conventions?student.id=${this.$route.params.id}`)
        .then(res => {
          if (res.data.length > 0) {
            const newConv = res.data[0]
            this.updateConvention(newConv)
          }
        })
        .catch(err => console.log(err))
      this.$http.get(`${process.env.VUE_APP_API_URL}/docsuivis?student.id=${this.$route.params.id}`)
        .then(res => {
          if (res.data.length > 0) {
            const newSuivi = res.data[0]
            this.updateDocsuivi(newSuivi)
          }
        })
        .catch(err => console.log(err))
      this.$http.get(`${process.env.VUE_APP_API_URL}/reviews?student.id=${this.$route.params.id}`)
        .then(res => {
          if (res.data.length > 0) {
            const newSatisfaction = res.data[0]
            this.updateSatisfaction(newSatisfaction)
          }
        })
        .catch(err => console.log(err))
    },
    showFile(a) {
      window.open(`${process.env.VUE_APP_API_URL + a.url}`)
    },
    linkToFile(item) {
      fetch(`${process.env.VUE_APP_API_URL + item.url}`)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `${item.title} - ${this.student.nom} ${this.student.prenom}`
          link.click()
        })
        .catch(console.error)
    },
    generateFormulaireSatifcation() {
      const data = {
        student: this.student,
        dateDebut: this.$date(new Date(this.student.debutFormation), 'dd/MM/yyyy'),
        dateFin: this.$date(new Date(this.student.finFormation), 'dd/MM/yyyy'),
        review: this.student.review,
        tuteur: this.student.tuteurs[0].nom,
      }
      this.$http.post(`${process.env.VUE_APP_API_URL}/reviews/generate-pdf`, data)
        .then(res => {
          if (res.status === 200) {
            this.updateSatisfaction(res.data)
          }
        })
        .catch(err => console.log(err))
        .finally()
    },
    solveFile(item) {
      switch (item.title) {
        case 'Facture':
          this.factureDialog = true

          return 'solved'
        case 'Convention':
          this.conventionDialog = true

          return 'solved'
        case 'Compte Rendu d\'Entretient':
          return this.$router.push({ name: 'student-creipef', params: { id: this.student.id } })
        case 'Document de suivi':
          this.docSuiviDialog = true

          return 'solved'
        case 'Formulaire de satisfaction':
          this.generateFormulaireSatifcation()

          return 'solved'
        default:
          console.log(item)

          return item
      }
    },
    updateInvoice(e) {
      this.factureDialog = false
      this.facture.generated = true
      this.facture.url = e.pdf.url
      this.facture.headline = `Facture ${e.number} - Crée le ${this.$date(new Date(e.created_at), 'dd/MM/yyyy à HH:mm')} par ${e.createdBy.displayName}`
    },
    updateCreipef(e) {
      this.creipef.generated = true
      this.creipef.url = e.pdf.url
      this.creipef.headline = `Crée le ${this.$date(new Date(e.created_at), 'dd/MM/yyyy à HH:mm')} par ${e.createdBy.displayName}`
    },
    updateConvention(e) {
      this.conventionDialog = false
      this.convention.generated = true
      this.convention.url = e.pdf.url
      this.convention.headline = `Créee le ${this.$date(new Date(e.created_at), 'dd/MM/yyyy à HH:mm')} par ${e.createdBy.displayName}`
    },
    updateDocsuivi(e) {
      this.docSuiviDialog = false
      this.docsuivi.generated = true
      this.docsuivi.url = e.pdf.url
      this.docsuivi.headline = `Créee le ${this.$date(new Date(e.created_at), 'dd/MM/yyyy à HH:mm')} par ${e.createdBy.displayName}`
    },
    updateSatisfaction(e) {
      this.satisfaction.generated = true
      this.satisfaction.url = e.pdf.url
      this.satisfaction.headline = `Formulaire de satisfaction - Crée le ${this.$date(new Date(e.created_at), 'dd/MM/yyyy à HH:mm')} par ${e.createdBy.displayName}`
    },
  },
}
</script>

<style>

</style>
