<template>
  <v-card class="overflow-hidden mt-5">
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon
        @click="$emit('toggleShow', 'course')"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        {{ contact.courses.length > 1 ? 'Formations' : 'Formation' }}
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiCertificateOutline }}
      </v-icon>
    </v-card-title>
    <v-divider>
    </v-divider>
    <v-expand-transition>
      <div v-show="show">
        <div v-if="contact.courses.length >0 ">
          <v-sheet class="d-flex justify-center">
            <v-chip-group
              v-model="selectedCourse"
              mandatory
              active-class="primary--text"
            >
              <v-chip
                v-for="(contactCourse, index) in contact.courses"
                :key="index"
              >
                {{ contactCourse.course.name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
          <v-row
            class="ma-4 h-full"
            justify="center"
          >
            <v-col
              cols="12"
              class="text-center"
            >
              <h2 class="mb-5">
                {{ contact.courses[selectedCourse].course.name }} ( {{ contact.courses[selectedCourse].course.duration / 60 }} heures)
              </h2>
              <p>
                En formation du
                <DateChip
                  :date="new Date(contact.courses[selectedCourse].start)"
                  @dateUpdated="updateCourseDate($event, 'start', contact.courses[selectedCourse]._id)"
                ></DateChip>
                au
                <DateChip
                  :date="new Date(contact.courses[selectedCourse].end)"
                  @dateUpdated="updateCourseDate($event, 'end', contact.courses[selectedCourse]._id)"
                >
                </DateChip>
              </p>
              <v-spacer>
              </v-spacer>
              <!--
              <p>Jour de rétractation : <b>{{ this.$workingDays.getWorkingDaysBefore(selectedCourse.start, 7) | date('dd MMMM yyyy') }}</b></p>
            -->
              <v-spacer>
              </v-spacer>
              <p>
                Durée de formation :
                <b>
                  {{ contact.courses[selectedCourse].course.duration / 60 }} heures
                </b>
              </p>
              <p v-if="contact.courses[selectedCourse].course.visio > 0">
                Durée visio : <b>{{ contact.courses[selectedCourse].course.visio }} heure(s)</b>
              </p>
              <div v-else>
                <p>
                  Visio : <b>
                    Non
                  </b>
                </p>
              </div>
              <v-divider class="mt-5">
              </v-divider>
            </v-col>
          </v-row>
          <v-row
            class="ma-4 h-full"
            justify="center"
          >
            <v-col
              v-if="contact.edofNumber"
              md="6"
              sm="12"
              class="text-center"
            >
              <a
                :href="`https://www.of.moncompteformation.gouv.fr/espace-prive/html/#/dossiers/detail/${contact.edofNumber}`"
                target="_blank"
              >
                <v-icon>
                  {{ icons.mdiApplicationImport }}
                </v-icon>
              </a>
              {{ contact.edofNumber }}
            </v-col>
            <v-col
              md="6"
              sm="12"
              class="text-center"
            >
              <div>
                {{ Number(contact.courses[selectedCourse].course.price).toLocaleString('fr-FR') }}
                <v-icon>
                  {{ icons.mdiCurrencyEur }}
                </v-icon>
              </div>
            </v-col>
            <v-col
              v-if="contact.courses[selectedCourse].tutor"
              md="6"
              sm="12"
              class="text-center"
            >
              <v-icon>
                {{ icons.mdiAccountBoxOutline }}
              </v-icon>
              Tutorat par : <br /><b>{{ contact.courses[selectedCourse].tutor.displayName }}</b>
            </v-col>
            <v-col
              v-if="contact.courses[selectedCourse].course.certification"
              md="6"
              sm="12"
              class="text-center"
            >
              <v-icon>
                {{ icons.mdiClipboardAccountOutline }}
              </v-icon>
              Certification : <br />{{ contact.courses[selectedCourse].certification }}
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row
            class="ma-4 h-full"
            justify="center"
          >
            <p>Ce contact n'a pas de formation associée.</p>
            <p>Lui en attribuer une ?</p>
          </v-row>
        </div>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            text
            class="white--text"
            color="primary"
            label
            @click="courseSelectDialog = true"
          >
            Selectionner formations
          </v-btn>
        </v-card-actions>
      </div>
    </v-expand-transition>
    <course-select-dialog
      :preselected-courses="contact.courses.map(x => x.course)"
      :dialog="courseSelectDialog"
      @closeCourseSelectDialog="courseSelectDialog =false"
      @selectedCourses="updateContactCourses"
    ></course-select-dialog>
  </v-card>
</template>

<script>

import {
  mdiApplicationImport, mdiCurrencyEur, mdiAccountBoxOutline, mdiClipboardAccountOutline,
  mdiCertificateOutline, mdiChevronUp, mdiChevronDown,
} from '@mdi/js'
import CourseSelectDialog from '@/components/CourseSelectDialog.vue'
import DateChip from '@/components/DateChip.vue'

export default {
  components: {
    CourseSelectDialog,
    DateChip,
  },
  props: {
    contact: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedCourse: 0,
      courseSelectDialog: false,
      certifications: [
        {
          nom: '',
        },
      ],
      durees: [
        {
          name: '2 mois',
          value: 2,
        },
        {
          name: '3 semaines',
          value: 3,
        },
        {
          name: '1 mois',
          value: 4,
        },
        {
          name: '5 semaines',
          value: 5,
        },
        {
          name: '6 semaines',
          value: 6,
        },
        {
          name: '2 mois',
          value: 8,
        },
        {
          name: '9 semaines',
          value: 9,
        },
        {
          name: '10 semaines',
          value: 10,
        },
        {
          name: '3 mois',
          value: 12,
        },
      ],
      icons: {
        mdiApplicationImport,
        mdiCurrencyEur,
        mdiAccountBoxOutline,
        mdiClipboardAccountOutline,
        mdiCertificateOutline,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
  computed: {

  },
  created() {
  },
  methods: {
    updateContactCourses(payload) {
      this.selectedCourse = 0
      const courses = []
      payload.forEach(course => {
        courses.push({
          course,
        })
      })
      this.$http.put(`${process.env.VUE_APP_API_URL}/contact/${this.contact._id}`, { courses })
        .then(res => {
          this.$emit('contactUpdate', { courses: res.data })
        })
        .catch(err => console.log(err))
        .finally()
    },
    updateCourseDate(newDate, time, courseId) {
      this.$http.put(`${process.env.VUE_APP_API_URL}/contact/update-course-date/${this.contact._id}`, { newDate, time, courseId })
        .then(res => {
          this.$emit('contactUpdate', { courses: res.data })
        })
        .catch(err => console.log(err))
        .finally()
    },

  },

}
</script>

<style>

</style>
